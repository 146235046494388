import React, { useState, useEffect } from 'react';

function Header() {
  const [workClass, setWorkClass] = useState(false);
  const [techStackClass, setTechStackClass] = useState(false);
  const [journalClass, setJournalClass] = useState(false);
  const [contactClass, setContactClass] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const workElement = document.querySelector('#work');
      const techStackElement = document.querySelector('#techstack');
      const journalElement = document.querySelector('#journal');
      const contactElement = document.querySelector('#contact');
      const header = document.querySelector('.header');

      if (workElement) {
        const topOfWork = workElement.getBoundingClientRect().top;
        if (topOfWork <= 1) {
          header.classList.add('work');
          setWorkClass(true);
        } else {
          header.classList.remove('work');
          setWorkClass(false);
        }
      }
      if (techStackElement) {
        const topOfTechStack = techStackElement.getBoundingClientRect().top;
        if (topOfTechStack <= 1) {
          header.classList.remove('work');
          header.classList.add('techStack');
          setTechStackClass(true);
        } else {
          header.classList.remove('techStack');
          setTechStackClass(false);
        }
      }
      if (journalElement) {
        const topOfJournal = journalElement.getBoundingClientRect().top;
        if (topOfJournal <= 100) {
          header.classList.add('journalOne');
        } else {
          header.classList.remove('journalOne');
        }
        if (topOfJournal <= 20) {
          header.classList.remove('techStack');
          header.classList.add('journal');
          setJournalClass(true);
        } else {
          header.classList.remove('journal');
          setJournalClass(false);
        }
      }
      if (contactElement) {
        const topOfContact = contactElement.getBoundingClientRect().top;
        if (topOfContact <= 50) {
          header.classList.remove('journal');
          header.classList.add('contact');
          setContactClass(true);
        } else {
          header.classList.remove('contact');
          setContactClass(false);
        }
      }
    };

    const sitePages = ['acne', 'garbs', 'deloitte', 'nhs', 'selectors', '3d-asset-creation-with-blender'];

    if (sitePages.some(page => document.location.pathname.includes(page))) {
      document.querySelector('.header').style.display = 'none';
    }
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header ${workClass ? 'work' : ''} ${techStackClass ? 'techStack' : ''} ${journalClass ? 'journal' : ''} ${contactClass ? 'contact' : ''}`}>
      <a className='work' href='#work' title='work'>
        <div>
          <p>work</p>
          <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>work</p>
          <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>work</p>
          <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
      </a>
      <a className='techStack' href='#techstack' title='tech stack'>
        <div>
          <p>tech stack</p>
          <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>tech stack</p>
          <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>tech stack</p>
          <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
      </a>
      <a className='journal' href='#journal' title='journal'>
        <div>
          <p>journal</p>
          <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>journal</p>
          <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>journal</p>
          <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
      </a>
      <a className='contact' href='#contact' title='contact'>
        <div>
          <p>contact</p>
          <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>contact</p>
          <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
        <div>
          <p>contact</p>
          <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
          </svg>
        </div>
      </a>
    </div>
  );
}

export default Header;
