import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';

function Landing() {
  const fluidCanvasRef = useRef(null);
  const cursorCanvas = useRef(null);
  const webglBallContainerRef = useRef(null);
  const [isPointerEventsEnabled, setIsPointerEventsEnabled] = useState(true);

  useEffect(() => {
    const loadFluidSimulations = async () => {
      // Wait for the component to be fully loaded before initializing the Fluid simulations
      if (fluidCanvasRef.current && cursorCanvas.current) {
        await import('webgl-fluid').then((module) => {
          // Fluid simulation for the ball canvas
          module.default(fluidCanvasRef.current, {
            AUTO: true,
            INTERVAL: 10,
            IMMEDIATE: true,
            TRIGGER: 'hover',
            SIM_RESOLUTION: 32,
            DYE_RESOLUTION: 1024,
            CAPTURE_RESOLUTION: 512,
            DENSITY_DISSIPATION: 0.05,
            VELOCITY_DISSIPATION: 0.05,
            PRESSURE: 0.1,
            PRESSURE_ITERATIONS: 2,
            CURL: 1,
            SPLAT_RADIUS: 1,
            SPLAT_FORCE: 500,
            SPLAT_COUNT: 50,
            SHADING: false,
            COLORFUL: true,
            COLOR_UPDATE_SPEED: 1,
            PAUSED: false,
            BACK_COLOR: { r: 245, g: 246, b: 255 },
            TRANSPARENT: true,
            BLOOM: true,
            BLOOM_ITERATIONS: 1,
            BLOOM_RESOLUTION: 256,
            BLOOM_INTENSITY: 1,
            BLOOM_THRESHOLD: 0.6,
            BLOOM_SOFT_KNEE: 0.7,
            SUNRAYS: true,
            SUNRAYS_RESOLUTION: 196,
            SUNRAYS_WEIGHT: 0.3,
          });

          // Fluid simulation for the cursor canvas
          module.default(cursorCanvas.current, {
            IMMEDIATE: false,
            TRIGGER: 'hover',
            SIM_RESOLUTION: 32,
            DYE_RESOLUTION: 1024,
            CAPTURE_RESOLUTION: 512,
            DENSITY_DISSIPATION: 5,
            VELOCITY_DISSIPATION: 2,
            PRESSURE: 0,
            PRESSURE_ITERATIONS: 20,
            CURL: 0.1,
            SPLAT_RADIUS: 0.1,
            SPLAT_FORCE: 500,
            SPLAT_COUNT: parseInt(Math.random() * 20) + 5,
            SHADING: true,
            COLORFUL: true,
            COLOR_UPDATE_SPEED: 100,
            PAUSED: false,
            BACK_COLOR: { r: 245, g: 246, b: 255 },
            TRANSPARENT: true,
            BLOOM: false,
            BLOOM_ITERATIONS: 8,
            BLOOM_RESOLUTION: 256,
            BLOOM_INTENSITY: 0.8,
            BLOOM_THRESHOLD: 0.6,
            BLOOM_SOFT_KNEE: 0.7,
            SUNRAYS: true,
            SUNRAYS_RESOLUTION: 196,
            SUNRAYS_WEIGHT: 0.3,
          });
        });
      }
    };

    loadFluidSimulations();

    const timeout = setTimeout(() => {
      setIsPointerEventsEnabled(false);
    }, 2200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (fluidCanvasRef.current) {
      // Set up Three.js renderer
      const renderer = new THREE.WebGLRenderer(); // Set alpha to true for transparent background
      renderer.setSize(window.innerWidth, window.innerHeight);

      // Create a scene
      const scene = new THREE.Scene();

      // Create a camera
      const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 100);
      camera.position.set(0, 0, 50); // Adjust camera position
      camera.lookAt(0, 0, 0); // Set camera target to the center of the scene

      // Create a sphere with basic material and texture from the fluid simulation
      const sphereGeometry = new THREE.SphereGeometry(1, 64, 64);
      const sphereMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 }); // Use MeshStandardMaterial
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // Color: white, Intensity: 0.5
      directionalLight.position.set(0, 0, 50); // Adjust light position
      directionalLight.intensity = 1; // Increase light intensity

      // Add the sphere to the scene
      scene.add(sphere);
      renderer.setClearColor(0xffffff); // Set background color to white

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();

      // Event listeners
      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const [skewDegX, setSkewDegX] = useState(0);
  const [skewDegY, setSkewDegY] = useState(0);
  const [photoSize, setPhotoSize] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const xPosition = e.clientX;
      const yPosition = e.clientY;
      const skewValueX = mapRange(xPosition, 0, window.innerWidth, -10, 10);
      const skewValueY = mapRange(yPosition, 0, window.innerHeight, -10, 10);
      setSkewDegX(skewValueX);
      setSkewDegY(skewValueY);
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const newSize = mapRange(scrollPosition, 0, 1 * window.innerHeight, 1, 2);
      setPhotoSize(newSize);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mapRange = (value, inMin, inMax, outMin, outMax) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  return (
    <div className='landingSection' id='landing'>
      <div className='landingInnerContainer'>
        <div className='content'>
          <div className='photoContainer'>
            <p className='backgroundText' style={{ transform: `translate(calc(-50% + ${skewDegX}px * -1), calc(-50% + ${skewDegY}px * -1 + .5rem)) skewX(${skewDegX * .5}deg)` }}>Hi, I'm Alfie</p>
          </div>
          <div className='body'>
            <p>I specialize in creating interactive web experiences, and have a passion for fusing visual immersion with user functionality to create exciting digital platforms.</p>
          </div>
        </div>
        <canvas ref={cursorCanvas} className="webgl"></canvas>
        <div className='webglBallContainer' style={{ transform: `translate(calc(${skewDegX}px * 5 - 50%), calc(${skewDegY}px * 5 - 50%))`}}>
          <div className='innerContainer'>
            <canvas ref={fluidCanvasRef} className="webglBall" style={{ pointerEvents: isPointerEventsEnabled ? 'none' : 'auto'}}></canvas>
            <div className='ballTextContainer'>
              <p style={{ transform: `translate(calc((${skewDegX}px * -6) - 50%), calc((${skewDegY}px * -6) - 50%)) skewX(${skewDegX * .5}deg)`}}>Hi, I'm Alfie</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
