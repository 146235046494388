import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import Fluid from 'webgl-fluid';

function Contact() {
  const fluidCanvasRef = useRef(null);
  const fluidCanvasRefTwo = useRef(null);
  const cursorCanvas = useRef(null);
  const webglBallContainerRef = useRef(null);
  const [isPointerEventsEnabled, setIsPointerEventsEnabled] = useState(true);

  useEffect(() => {
    const initFluidSimulations = () => {
      Fluid(fluidCanvasRef.current, {
        AUTO: true,
        INTERVAL: 10,
        IMMEDIATE: true,
        TRIGGER: 'hover',
        SIM_RESOLUTION: 32,
        DYE_RESOLUTION: 1024,
        CAPTURE_RESOLUTION: 512,
        DENSITY_DISSIPATION: 0.05,
        VELOCITY_DISSIPATION: 0.05,
        PRESSURE: 0.1,
        PRESSURE_ITERATIONS: 2,
        CURL: 1,
        SPLAT_RADIUS: 1,
        SPLAT_FORCE: 500,
        SPLAT_COUNT: 50,
        SHADING: false,
        COLORFUL: true,
        COLOR_UPDATE_SPEED: 1,
        PAUSED: false,
        BACK_COLOR: { r: 245, g: 246, b: 255 },
        TRANSPARENT: true,
        BLOOM: true,
        BLOOM_ITERATIONS: 1,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: 1,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: 0.3,
      });

      Fluid(fluidCanvasRefTwo.current, {
        AUTO: true,
        INTERVAL: 10,
        IMMEDIATE: true,
        TRIGGER: 'hover',
        SIM_RESOLUTION: 32,
        DYE_RESOLUTION: 1024,
        CAPTURE_RESOLUTION: 512,
        DENSITY_DISSIPATION: 0.05,
        VELOCITY_DISSIPATION: 0.05,
        PRESSURE: 0.1,
        PRESSURE_ITERATIONS: 2,
        CURL: 1,
        SPLAT_RADIUS: 1,
        SPLAT_FORCE: 500,
        SPLAT_COUNT: 50,
        SHADING: false,
        COLORFUL: true,
        COLOR_UPDATE_SPEED: 1,
        PAUSED: false,
        BACK_COLOR: { r: 245, g: 246, b: 255 },
        TRANSPARENT: true,
        BLOOM: true,
        BLOOM_ITERATIONS: 1,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: 1,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: 0.3,
      });

      // Fluid simulation for the cursor canvas
      Fluid(cursorCanvas.current, {
        IMMEDIATE: false,
        TRIGGER: 'hover',
        SIM_RESOLUTION: 32,
        DYE_RESOLUTION: 1024,
        CAPTURE_RESOLUTION: 512,
        DENSITY_DISSIPATION: 5,
        VELOCITY_DISSIPATION: 2,
        PRESSURE: 0,
        PRESSURE_ITERATIONS: 20,
        CURL: 0.1,
        SPLAT_RADIUS: 0.1,
        SPLAT_FORCE: 500,
        SPLAT_COUNT: parseInt(Math.random() * 20) + 5,
        SHADING: true,
        COLORFUL: true,
        COLOR_UPDATE_SPEED: 100,
        PAUSED: false,
        BACK_COLOR: { r: 245, g: 246, b: 255 },
        TRANSPARENT: true,
        BLOOM: false,
        BLOOM_ITERATIONS: 8,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: 0.8,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: 0.3,
      });
    };

    // Wait for the component to be fully loaded before initializing the Fluid simulations
    if (fluidCanvasRef.current && fluidCanvasRefTwo.current && cursorCanvas.current) {
      initFluidSimulations();
    }

    const timeout = setTimeout(() => {
      setIsPointerEventsEnabled(false);
    }, 2200);

    return () => clearTimeout(timeout);

  }, [fluidCanvasRef, fluidCanvasRefTwo, cursorCanvas]);

  useEffect(() => {
    if (fluidCanvasRef.current) {
      // Set up Three.js renderer
      const renderer = new THREE.WebGLRenderer(); // Set alpha to true for transparent background
      renderer.setSize(window.innerWidth, window.innerHeight);
  
      // Create a scene
      const scene = new THREE.Scene();
  
      // Create a camera
      const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 100);
      camera.position.set(0, 0, 50); // Adjust camera position
      camera.lookAt(0, 0, 0); // Set camera target to the center of the scene
  
      // Create a sphere with basic material and texture from the fluid simulation
      const sphereGeometry = new THREE.SphereGeometry(1, 64, 64);
      const sphereMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 }); // Use MeshStandardMaterial
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // Color: white, Intensity: 0.5
      directionalLight.position.set(0, 0, 50); // Adjust light position
      directionalLight.intensity = 1; // Increase light intensity
      
      // Add the sphere to the scene
      scene.add(sphere);
      renderer.setClearColor(0xffffff); // Set background color to white

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();
  
      // Event listeners
      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    if (fluidCanvasRefTwo.current) {
      // Set up Three.js renderer
      const renderer = new THREE.WebGLRenderer(); // Set alpha to true for transparent background
      renderer.setSize(window.innerWidth, window.innerHeight);
  
      // Create a scene
      const scene = new THREE.Scene();
  
      // Create a camera
      const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 100);
      camera.position.set(0, 0, 50); // Adjust camera position
      camera.lookAt(0, 0, 0); // Set camera target to the center of the scene
  
      // Create a sphere with basic material and texture from the fluid simulation
      const sphereGeometry = new THREE.SphereGeometry(1, 64, 64);
      const sphereMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 }); // Use MeshStandardMaterial
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // Color: white, Intensity: 0.5
      directionalLight.position.set(0, 0, 50); // Adjust light position
      directionalLight.intensity = 1; // Increase light intensity
      
      // Add the sphere to the scene
      scene.add(sphere);
      renderer.setClearColor(0xffffff); // Set background color to white

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();
  
      // Event listeners
      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleEmailClick = () => {
    const emailElement = document.querySelector('.email');
    if (emailElement) {
      const email = emailElement.textContent.trim();
      const originalTextDecoration = emailElement.style.textDecoration; // Store original text decoration
      navigator.clipboard.writeText(email)
        .then(() => {
          emailElement.style.textDecoration = 'none';
          setTimeout(() => {
            emailElement.style.textDecoration = originalTextDecoration; // Restore original text decoration
          }, 1000); 
        })
        .catch((error) => {
          console.error('Failed to copy email to clipboard:', error);
          emailElement.style.textDecoration = originalTextDecoration; // Restore original text decoration
        });
    } else {
      console.error('Email element not found.');
    }
  };
  
  return (
    <div className='contactSection' id='contact'>
      <p className='contactTitle'>Get In Touch</p>
      <div className='helpContainer'>
        <p className='title'>Need help with</p>
        <p>Design</p>
        <p>Development</p>
        <p>Deployment</p>
        <p>CMS Creation</p>
        <p>Branding</p>
        <p>Marketing</p>
      </div>
      <div className='socials'>
        <div>
          <p className='title'>Email</p>
          <p className='email' onClick={handleEmailClick}>alfiebatten123@gmail.com</p>
        </div>
        <div>
          <p className='title'>LinkedIn</p>
          <a className='linkedIn' href='https://www.linkedin.com/in/alfiebatten/' target='_blank' title='alfie batten'>Alfie Batten</a>
        </div>
      </div>
      <canvas ref={cursorCanvas} className="webgl"></canvas>
      <div className='webglBallContainer'>
        <div className='innerContainer innerContainerOne'>
          <canvas ref={fluidCanvasRef} className="webglBall" style={{ pointerEvents: isPointerEventsEnabled ? 'none' : 'auto'}}></canvas>
        </div>
      </div>
      <div className='webglBallContainer'>
        <div className='innerContainer innerContainerTwo'>
          <canvas ref={fluidCanvasRefTwo} className="webglBall" style={{ pointerEvents: isPointerEventsEnabled ? 'none' : 'auto'}}></canvas>
        </div>
      </div>
      </div>
  );
}

export default Contact;
