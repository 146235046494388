import React, { useState, useEffect } from 'react';
import ACNEPhone from '../../assets/pages/workPages/acne/acnePhone.png'
import ACNELandingMobile from '../../assets/pages/workPages/acne/acneLandingMobile.png';
import ACNEImage from '../../assets/pages/workPages/acne/acneImage.png'
import ACNEImageMobile from '../../assets/pages/workPages/acne/acneImageMobile.png'

import {motion as m} from 'framer-motion';
function ACNE() {
    const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const translateYUp = -5 + ((scrollProgress * 2) * -0.6);
            const translateYDown = -40 + (scrollProgress * 0.6);
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100);

            document.querySelectorAll('.up').forEach(element => {
                element.style.transform = `translateY(${translateYUp}vh)`;
            });

            document.querySelectorAll('.down').forEach(element => {
                element.style.transform = `translateY(${translateYDown}vh)`;
            });

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
      useEffect(() => {
        const handleScroll = () => {
            const contentElement = document.querySelector('.content');
            const workPageHeader = document.querySelector('.workPageHeader');
          
            if (contentElement && workPageHeader) {
              const topOfContent = contentElement.getBoundingClientRect().top;
              const headerHeight = workPageHeader.offsetHeight;
              const threshold = -headerHeight / 2;
          
              if (topOfContent <= -threshold) {
                workPageHeader.classList.add('switch');
                setHeaderSwitch(true);
              } else {
                workPageHeader.classList.remove('switch');
                setHeaderSwitch(false);
              }
            }
          };
          
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='acnePage'>
        <m.div
            className='acneOverlay'
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: .5, delay: .5 }}
        ></m.div>
        <div className={`workPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9) translate(-12.5vw, -12.5vh)'}} animate={{transform: 'scale(1) translate(-12.5vw, -12.5vh)'}} transition={{duration: .4, delay: .75, ease: "easeOut"}} exit={{opacity: 0}} className='grid'>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='acnePhone' src={ACNEPhone}/>
                    </div>
                </m.div>
            </m.div>
            <div className='landingMobile'>
                <img alt='acne landing image' src={ACNELandingMobile}/>
            </div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>ACNE Creative</p>
                    <p className='slogan'>Redeveloping and centralising the ACNE brand in line with their integration into Deloitte.</p>
                </div>
                <div>
                    <p className='date'>2022-2023</p>
                    <p className='role'>Tech consultant & software developer</p>
                </div>
            </div>
            <div className='body'>
                <div className='textContainer'>
                    <p>ACNE was founded in Stockholm, Sweden in 1996 as a creative collective that focused on graphic design, film, production and advertising. In 2006 the company split, with ACNE Studios becoming a standalone company and fashion house. ACNE Creative continued to thrive, growing to a collective of 10 international offices. ACNE Creative was acquired by Deloitte in 2017, in line with the acquisition I joined ACNE to make the integration as seamless as possible and to revamp and centralise their existing website, with the addition of a backend CMS platform.</p>
                    <p className='contentHeading'>Integration</p>
                    <p>During the first few months of my engagement, I worked alongside ACNE to ensure their official integration into Deloitte went smoothly and efficiently. They operated very well as an independent business, however, as they were moving into the wider company they would need to adhere to Deloitte processes. In line with this, I set up and ran workshops both virtually and in person to train ACNE on the integral Deloitte processes, allowing them to establish a fundamental understanding of their new ways of working ahead of the official integration. I brought together their diverse range of clientele and intermediaries to lead the process of passing in-house risk analysis and ensuring efficient business relations. In addition to transferring and managing their invoices/payment system to Deloitte's central services; as well as a range of other confidential internal tasks and processes. ACNE has now successfully integrated into Deloitte and continues to flourish as a business.</p>
                    <p className='contentHeading'>Website Redevelopment</p>
                    <p>Alongside the integration, I also led the redevelopment and centralisation of ACNE’s website. Prior to my involvement, each office within the ACNE family had its own decentralised site and domain. I collaborated with one of ACNE’s in-house designers to create refreshed and modern wireframes that better matched the core values of the company. The new site was developed in React. One of my main reasons for choosing so was to utilise its effectiveness in code reusability; through its component-like structure, as many pages on the site would differ in content but share the same layout and style. Over the next few months I developed out the site, testing different design approaches through A/B testing visual features in partnership with the designer. When we were happy with the design and responsiveness of the site, I commenced a series of lighthouse reports (a web insight tool for generating metrics) to see where we could improve on performance, SEO and overall best practice. As well as making sure all assets were served in next-gen formats to maximise efficiency and decrease contentful paint load times. As the site was now a central hub for all offices within the ACNE family, the domain was transferred from acnelondon.com to acnecreative.com to better represent the rebrand.</p>
                    <p className='contentHeading'>CMS Integration</p>
                    <p>A large majority of the written content on the site was written in JSON and stored in the frontend repository alongside the rest of the code. This posed issues for ACNE as they were unable to edit, upload or delete content from their site without the constant need for a developer on hand. I pitched the idea of implementing a backend CMS (Content Management System), that would store written content as entries within a corresponding collection (i.e. workpieces, articles, etc). This would be displayed on a tailored frontend dashboard, removing the need for technical expertise to change content. ACNE was keen to get this implemented, and I wanted to ensure it was integrated without causing performance issues or vastly changing the foundational structure of the site. Due to this I chose to work with the headless CMS platform Strapi, as it allows for easy data transfer through API calls, and has a well-thought-out documentation; along with it being open source, resulting in an abundance of resources if we happen to encounter obstacles. I built out and connected the platform, making the upkeep of the website far more efficient and comprehensible. The whole team at ACNE was incredibly pleased with the result.</p>
                    <div>
                        <img alt='acne image desktop' src={ACNEImage} className="desktop"/>
                        <img alt='acne image mobile' src={ACNEImageMobile}className="mobile"/>
                    </div>
                </div>
                <div className='navContainer'>
                    <a  title='previous'></a>
                    <a className='garbs' href='/garbs' title='next'>
                        <div>
                            <p>garbs</p>
                            <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                            </svg>
                        </div>
                        <div>
                            <p>garbs</p>
                            <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                            </svg>
                        </div>
                        <div>
                            <p>garbs</p>
                            <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ACNE;
