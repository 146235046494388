import React, { useState, useEffect } from 'react';
import NHSPhone from '../../assets/pages/workPages/nhs/nhsPhone.png';
import NHSLandingMobile from '../../assets/pages/workPages/nhs/nhsLandingMobile.png'
import {motion as m} from 'framer-motion';

function NHS() {
    const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const translateYUp = -5 + ((scrollProgress * 2) * -0.6);
            const translateYDown = -40 + (scrollProgress * 0.6);
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100); // Convert 6rem to pixels and calculate scale

            document.querySelectorAll('.up').forEach(element => {
                element.style.transform = `translateY(${translateYUp}vh)`;
            });

            document.querySelectorAll('.down').forEach(element => {
                element.style.transform = `translateY(${translateYDown}vh)`;
            });

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

      useEffect(() => {
        const handleScroll = () => {
            const contentElement = document.querySelector('.content');
            const workPageHeader = document.querySelector('.workPageHeader');
          
            if (contentElement && workPageHeader) {
              const topOfContent = contentElement.getBoundingClientRect().top;
              const headerHeight = workPageHeader.offsetHeight;
              const threshold = -headerHeight / 2;
          
              if (topOfContent <= -threshold) {
                workPageHeader.classList.add('switch');
                setHeaderSwitch(true);
              } else {
                workPageHeader.classList.remove('switch');
                setHeaderSwitch(false);
              }
            }
          };
          
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='nhsPage'>
        <m.div
            className='nhsOverlay'
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: .5, delay: .5 }}
        ></m.div>
        <div className={`workPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9) translate(-12.5vw, -12.5vh)'}} animate={{transform: 'scale(1) translate(-12.5vw, -12.5vh)'}} transition={{duration: .4, delay: .75, ease: "easeOut"}} exit={{opacity: 0}} className='grid'>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='nhsPhone' src={NHSPhone}/>
                    </div>
                </m.div>
            </m.div>
            <div className='landingMobile'>
                <img alt='nhs landing image' src={NHSLandingMobile}/>
            </div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>NHS</p>
                    <p className='slogan'>Developing and maintaining the site for NHS covid precautions</p>
                </div>
                <div>
                    <p className='date'>2020</p>
                    <p className='role'>Software Developer</p>
                </div>
            </div>
            <div className='body'>
            <div className='textContainer'>
                    <p>In 2020, I worked alongside the NHS through the Coronavirus Pandemic, to build out a software platform to minimise the spread of the virus. Test kits had been developed and would enable people to self test, however these kits needed to be accessed quickly and without having to leave the house. I worked alongside the in-house NHS development team to build a platform where these kits could be delivered to the public.</p>
                    <p>The NHS has very specific style guidelines, meaning my role was purely to develop out the platform and not design the look of the site. User journeys had been created by the NHS development team, consisting of the flow of questions users would need to answer, to determine how best to be treated. Standard web protocols were used to build the foundations of the site, alongside using an in-house NHS frontend framework to build out the pages and user interactions. Due to the nature of the pandemic, the guidelines, best practices and solutions were ever-changing, resulting in a constant need for making sure the site matched the new research. This meant the user journeys were being changed and tested daily, to optimise the process and ensure users were being asked the right questions. This was fantastic experience, as it taught me to build out platforms quickly on tight deadlines and grew my confidence in my own abilities.</p>
                    <p>Over the course of my time on this project I continued to develop and restructure the site. Starting off purely as a way to order test kits to later becoming a central hub for COVID precautions, allowing you to get treatment and book vaccine appointments. I feel great privilege to have worked on and developed a site that was needed by so many, and that was such an integral part of controlling the pandemic.</p>
                    <div className='navContainer'>
                        <a className='deloitte' href='/deloitte' title='previous'>
                            <div>
                                <p>deloitte</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>deloitte</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>deloitte</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                        <a className='selectors' href='/selectors' title='next'>
                            <div>
                                <p>selectors</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>selectors</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>selectors</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default NHS;
