import React, { useRef, useState, useEffect } from 'react';
import Fluid from 'webgl-fluid';
import html from '../assets/components/techStackComponent/html.png';
import css from '../assets/components/techStackComponent/css.png';
import js from '../assets/components/techStackComponent/js.png';
import react from '../assets/components/techStackComponent/react.png';
import scss from '../assets/components/techStackComponent/scss.png';
import json from '../assets/components/techStackComponent/json.png';
import terminal from '../assets/components/techStackComponent/terminal.png';
import github from '../assets/components/techStackComponent/github.png';
import bitbucket from '../assets/components/techStackComponent/bitbucket.png';
import shopify from '../assets/components/techStackComponent/shopify.png';
import netlify from '../assets/components/techStackComponent/netlify.png';
import ghost from '../assets/components/techStackComponent/ghost.png';
import sanity from '../assets/components/techStackComponent/sanity.png';
import figma from '../assets/components/techStackComponent/figma.png';
import adobe from '../assets/components/techStackComponent/adobe.png';
import framer from '../assets/components/techStackComponent/framer.png';
import spline from '../assets/components/techStackComponent/spline.png';
import strapi from '../assets/components/techStackComponent/strapi.png';
import blender from '../assets/components/techStackComponent/blender.png';
import ableton from '../assets/components/techStackComponent/ableton.png';
import davinciResolve from '../assets/components/techStackComponent/davinciResolve.png';

const syncPointer = ({ x: pointerX, y: pointerY }) => {
    const x = pointerX.toFixed(2);
    const y = pointerY.toFixed(2);
    const xp = (pointerX / window.innerWidth).toFixed(2);
    const yp = (pointerY / window.innerHeight).toFixed(2);
  
    document.documentElement.style.setProperty('--x', x);
    document.documentElement.style.setProperty('--xp', xp);
    document.documentElement.style.setProperty('--y', y);
    document.documentElement.style.setProperty('--yp', yp);
  };

function TechStack() {
    const cursorCanvas = useRef(null);
    
    useEffect(() => {
        document.body.addEventListener('pointermove', syncPointer);
    
        return () => {
          document.body.removeEventListener('pointermove', syncPointer);
        };
      }, []);


      useEffect(() => {
        const initFluidSimulations = () => {
    
          Fluid(cursorCanvas.current, {
            IMMEDIATE: false,
            TRIGGER: 'hover',
            SIM_RESOLUTION: 32,
            DYE_RESOLUTION: 1024,
            CAPTURE_RESOLUTION: 512,
            DENSITY_DISSIPATION: 5,
            VELOCITY_DISSIPATION: 2,
            PRESSURE: 0,
            PRESSURE_ITERATIONS: 20,
            CURL: 0.1,
            SPLAT_RADIUS: 0.02,
            SPLAT_FORCE: 500,
            SPLAT_COUNT: parseInt(Math.random() * 20) + 5,
            SHADING: true,
            COLORFUL: true,
            COLOR_UPDATE_SPEED: 100,
            PAUSED: false,
            BACK_COLOR: { r: 245, g: 246, b: 255 },
            TRANSPARENT: true,
            BLOOM: false,
            BLOOM_ITERATIONS: 8,
            BLOOM_RESOLUTION: 256,
            BLOOM_INTENSITY: 0.8,
            BLOOM_THRESHOLD: 0.6,
            BLOOM_SOFT_KNEE: 0.7,
            SUNRAYS: true,
            SUNRAYS_RESOLUTION: 196,
            SUNRAYS_WEIGHT: 0.3,
          });
        };
    
        if (cursorCanvas.current) {
          initFluidSimulations();
        }
    
      }, [cursorCanvas]);
    
  return (
    <div className='techStackSection' id='techstack'>
        <div className='titleContainer'>
            <p className='title'>tech stack</p>
        </div>
        <div className='grid'>
            <div className='item fadein html'>
                <img alt='htmlLogo' className='logo' src={html}/>
                <p className='software'>HTML</p>
                <p className='text'>HTML (HyperText Markup Language), is the standard markup language for creating web pages. I have an extensive understanding of HTML principles and best practices, as a result of several years of experience in development related projects.</p>
            </div>
            <div className='item fadein css'>
                <img alt='cssLogo' className='logo' src={css}/>
                <p className='software'>CSS</p>
                <p className='text'>CSS (Cascading Style Sheets), is the standard style sheet language for applying and manipulating visual aspects of web pages. I have a deep understanding of CSS properties and its multitude of use cases. As well as in a depth understand and overall passion for UI/UX design.</p>
            </div>
            <div className='item fadein js'>
                <img alt='jsLogo' className='logo' src={js}/>
                <p className='software'>JS</p>
                <p className='text'>JS (JavaScript), is a programming language and core technology of the web, providing interactivity to elements on sites, allowing you to dynamically update content and connect to other parts of the web. I thoroughly enjoy JS and have a complex understanding of a plethora of vanilla and third-party plugin principles.</p>
            </div>
            <div className='item fadein react'>
                <img alt='reactLogo' className='logo' src={react}/>
                <p className='software'>React</p>
                <p className='text'>React is a frontend JS library, using a component structure to build user interfaces. I have several years professional experience developing in React, as such, I’ve gained vast knowledge of the fundamentals and wider scope, as well as best practises in code reusability.</p>
            </div>
            <div className='item fadein scss'>
                <img alt='scssLogo' className='logo' src={scss}/>
                <p className='software'>SCSS</p>
                <p className='text'>SCSS (Sassy Cascading Style Sheets) is a more advanced version of CSS allowing for more complexity in styling. I have an in-depth understand of SCSS principles and its capabilities.</p>
            </div>
            <div className='item fadein json'>
                <img alt='jsonLogo' className='logo' src={json}/>
                <p className='software'>JSON</p>
                <p className='text'>JSON (JavaScript Object Notation) is a file format for storing and transporting data. I have vast experience in using JSON to build and deploy websites as well as using to fetch and display content in a frontend database.</p>
            </div>
            <div className='item fadein terminal'>
                <img alt='terminalLogo' className='logo' src={terminal}/>
                <p className='software'>Terminal</p>
                <p className='text'>Terminal is a command-line interpreter used for executing commands on your operating system. I have extensive knowledge of terminal navigation as well as various third party packages for running and building codebases.</p>
            </div>
            <div className='item fadein github'>
                <img alt='githubLogo' className='logo' src={github}/>
                <p className='software'>Github</p>
                <p className='text'>GitHub is a repository platform used for storing and sharing code. I have several years experience using GitHub as well as the respective coding language git, through working on large-scale collaborative projects to small personal projects.</p>
            </div>
            <div className='item fadein bitbucket'>
                <img alt='bitbucketLogo' className='logo' src={bitbucket}/>
                <p className='software'>Bitbucket</p>
                <p className='text'>Bitbucket is a repository platform and hosting service provided by Atlassian. Similarly to Github I have several years experience with Bitbucket, leaving me with a great understanding in repository organisation and the build capabilities.</p>
            </div>
            <div className='item fadein shopify'>
                <img alt='shopifyLogo' className='logo' src={shopify}/>
                <p className='software'>Shopify</p>
                <p className='text'>Shopify is an e-commerce platform for seamlessly creating and deploying online shops. I have a great understanding of Shopify through personal projects as well as its extensive capabilities through custom coding components.</p>
            </div>
            <div className='item fadein netlify'>
                <img alt='netlifyLogo' className='logo' src={netlify}/>
                <p className='software'>Netlify</p>
                <p className='text'>Netlify is a serverless, backend build and deployment platform. I have several years of experience using Netlify to deploy projects, ranging from industry-leading clients to startups and personal projects.</p>
            </div>
            <div className='item fadein ghost'>
                <img alt='ghostLogo' className='logo' src={ghost}/>
                <p className='software'>Ghost</p>
                <p className='text'>Ghost is an open-source content management and publishing platform used for storing and fetching data. I have experience in building and connecting Ghost databases through professional internal projects.</p>
            </div>
            <div className='item fadein sanity'>
                <img alt='sanityLogo' className='logo' src={sanity}/>
                <p className='software'>Sanity</p>
                <p className='text'>Sanity is a headless CMS platform allowing you to store manage and filter content. I have experience in building out Sanity databases and creating frontend form submissions through developing an online platform for a startup.</p>
            </div>
            <div className='item fadein strapi'>
                <img alt='strapiLogo' className='logo' src={strapi}/>
                <p className='software'>Strapi</p>
                <p className='text'>Strapi is a JavaScript headless backend CMS platform for storing and fetching content. I have experience in building out and connecting Strapi to frontend services for an industry leading client.</p>
            </div>
            <div className='item fadein figma'>
                <img alt='figmaLogo' className='logo' src={figma}/>
                <p className='software'>Figma</p>
                <p className='text'>Figma is a web design platform for interface design. I have several years of experience in Figma, working on large scale collaborative projects to, individual personal and freelance projects.</p>
            </div>
            <div className='item fadein adobe'>
                <img alt='adobeLogo' className='logo' src={adobe}/>
                <p className='software'>Adobe</p>
                <p className='text'>Adobe is digital technology company housing a suite of creative design platforms. I have experience in a range of their services, such as Photoshop, Illustrator, Premier Pro and XD.</p>
            </div>
            <div className='item fadein framer'>
                <img alt='framerLogo' className='logo' src={framer}/>
                <p className='software'>Framer</p>
                <p className='text'>Framer is a site builder and CMS platform used for seamlessly creating aesthetically pleasing sites. I have experience in both Framers site builder and CMS platform, as well as an in-depth understanding of their React library Framer Motion.</p>
            </div>
            <div className='item fadein blender'>
                <img alt='blenderLogo' className='logo' src={blender}/>
                <p className='software'>Blender</p>
                <p className='text'>Blender is an open-source 3D creation suite for modelling, texturing and rendering 3D environments. I have experience in designing models for personal projects, and have created 3D art pieces that have sold.</p>
            </div>
            <div className='item fadein spline'>
                <img alt='splineLogo' className='logo' src={spline}/>
                <p className='software'>Spline</p>
                <p className='text'>Spline is a design software for creating interactive 3D web experiences. I have experience through personal projects in importing 3D models into sites and giving them functionality to create immersive and unique websites.</p>
            </div>
            <div className='item fadein ableton'>
                <img alt='abletonLogo' className='logo' src={ableton}/>
                <p className='software'>Ableton</p>
                <p className='text'>Ableton is a music technology company based in Germany. I have several years of experience in Ableton Live, a software for music production, as well as an overall passion for producing music.</p>
            </div>
            <div className='item fadein davinciResolve'>
                <img alt='davinchiResolveLogo' className='logo' src={davinciResolve}/>
                <p className='software'>DaVinci Resolve</p>
                <p className='text'>DaVinci Resolve is a visual effects, video editing and post-production software application for MacOS. I have experience in filming, editing and rendering video content for website asset creation.</p>
            </div>
        </div>
        <canvas ref={cursorCanvas} className="webgl"></canvas>
    </div>
  );
}

export default TechStack;
