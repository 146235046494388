import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './scss/setup/index.scss';
import Cursor from './components/cursor';
import ACNE from './pages/work/acne';
import Garbs from './pages/work/garbs';
import Deloitte from './pages/work/deloitte';
import NHS from './pages/work/nhs';
import Selectors from './pages/work/selectors';
import Journal1 from './pages/journal/journal1';
import Header from './components/header';
import Landing from './components/landing';
import Work from './components/work';
import TechStack from './components/techStack';
import Journal from './components/journal';
import Contact from './components/contact';
import Footer from './components/footer';

const HomepageLayout = ({ onComplete }) => {
  useEffect(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  return (
    <>
      <Landing />
      <div className='sectionContainer'>
        <Work />
        <TechStack />
        <Journal />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const pageTransitionHomepage = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 },
};

const pageTransitionNone = {
  initial: { opacity: 1, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 1, x: 0 },
};

const Homepage = () => {
  const location = useLocation();
  const [whiteLogo, setWhiteLogo] = useState(false);
  const [gradient, setGradient] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const workElement = document.querySelector('.workSection');
    if (workElement) {
      const workRect = workElement.getBoundingClientRect();

      if (workRect.bottom <= 43) {
        setWhiteLogo(false);
        setGradient(false);
      } else {
        setWhiteLogo(scrollTop > 43);
        setGradient(scrollTop > 0);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the current route is the homepage
  const isHomepage = location.pathname === '/';

  return (
    <div className="homepage-container">
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={isHomepage ? pageTransitionHomepage : pageTransitionNone}
          transition={{ duration: .5, delay: .5 }}
        >
          <Header whiteLogo={whiteLogo} gradient={gradient} />
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={<HomepageLayout onComplete={handleScroll} />}
            />
            <Route path="/acne" element={<ACNE />} />
            <Route path="/garbs" element={<Garbs />} />
            <Route path="/deloitte" element={<Deloitte />} />
            <Route path="/nhs" element={<NHS />} />
            <Route path="/selectors" element={<Selectors />} />
            <Route path="/3d-asset-creation-with-blender" element={<Journal1 />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
      <Cursor />
    </div>
  );
};

const App = () => (
  <Router>
    <Homepage />
  </Router>
);

const homepageElement = document.getElementById('homepage');
const homepage = createRoot(homepageElement);
homepage.render(<App />);
