import React, { useRef, useState, useEffect } from 'react';
import Fluid from 'webgl-fluid';

import ACNEPhoneVid from '../assets/components/workComponent/acne/acnePhoneVid.mp4';
import ACNEPhoneImage from '../assets/components/workComponent/acne/acnePhoneImage.png';

import GarbsPhoneVid from '../assets/components/workComponent/garbs/garbsPhoneVid.mp4';
import GarbsPhoneImage from '../assets/components/workComponent/garbs/garbsPhoneImage.png';

import DeloittePhoneVid from '../assets/components/workComponent/deloitte/deloittePhoneVid.mp4';
import DeloittePhoneImage from '../assets/components/workComponent/deloitte/deloittePhoneImage.png';

import NHSPhoneVid from '../assets/components/workComponent/nhs/nhsPhoneVid.mp4';
import NHSPhoneImage from '../assets/components/workComponent/nhs/nhsPhoneImage.png';

import SelectorsPhoneVid from '../assets/components/workComponent/selectors/selectorsPhoneVid.mp4';
import SelectorsPhoneImage from '../assets/components/workComponent/selectors/selectorsPhoneImage.png';

import ComingSoonPhoneImage from '../assets/components/workComponent/comingSoon/comingSoonPhoneImage.png';

function Work() {
    useEffect(() => {
        const cursor = document.querySelector('.cursor');
        const ball = document.querySelector('.ball');
        const exploreWork = document.querySelector('.exploreWork');

      
        let delayTimer; // Declare a variable to hold the timer reference

        const handleMouseEnter = (event) => {
        if (cursor) {
            cursor.style.backgroundColor = 'rgba(255,255,255,.05)';
            cursor.style.backdropFilter = 'blur(1.5px)';
            cursor.style.border = '1.25px solid rgba(255,255,255,.2)';
            cursor.style.transform = 'scale(2) translate(-25%,-25%)';
            ball.style.opacity = 0;
            exploreWork.style.opacity = 1;
        }
        
        // Check if the element being hovered over is a workPiece
        if (event.target.classList.contains('workPiece')) {
            const video = event.target.querySelector('.phoneVideo');
            if (video) {
            // Set a timeout to start the video after 0.25 seconds
            delayTimer = setTimeout(() => {
                // Set playback rate to 2 times speed
                video.playbackRate = 1.5;
                // Play the video and set loop to true
                video.play();
                video.loop = true;
            }, 230); // 250 milliseconds = 0.25 seconds
            }
        }
        };

        const handleMouseLeave = (event) => {
            if (cursor) {
                cursor.style.backgroundColor = 'white';
                cursor.style.border = '0px';
                cursor.style.transform = 'scale(1) translate(-50%,-50%)';
                exploreWork.style.opacity = 0;
                ball.style.opacity = 1;

            }
            
            // Check if the element being hovered over is a workPiece
            if (event.target.classList.contains('workPiece')) {
                const video = event.target.querySelector('.phoneVideo');
                if (video) {
                    // Set a timeout to pause the video after 1 second
                    const timeout = setTimeout(() => {
                        // Pause the video
                        video.pause();
                        // Reset the video to the beginning
                        video.currentTime = 0;
                    }, 750); // 1000 milliseconds = 1 second
                    
                    // Check if the mouse re-enters the element before the timeout expires
                    event.target.addEventListener('mouseenter', () => {
                        clearTimeout(timeout); // Cancel the timeout
                    });
                }
            }
        };

        const workPieces = document.querySelectorAll('.workPiece');
      
        workPieces.forEach((workPiece) => {
          workPiece.addEventListener('mouseenter', handleMouseEnter);
          workPiece.addEventListener('mouseleave', handleMouseLeave);
        });
      
        return () => {

          workPieces.forEach((workPiece) => {
            workPiece.removeEventListener('mouseenter', handleMouseEnter);
            workPiece.removeEventListener('mouseleave', handleMouseLeave);
          });
        };
      }, []);    
      const cursorCanvas = useRef(null);

      useEffect(() => {
        const initFluidSimulations = () => {
    
          // Fluid simulation for the cursor canvas
          Fluid(cursorCanvas.current, {
            IMMEDIATE: false,
            TRIGGER: 'hover',
            SIM_RESOLUTION: 32,
            DYE_RESOLUTION: 1024,
            CAPTURE_RESOLUTION: 512,
            DENSITY_DISSIPATION: 5,
            VELOCITY_DISSIPATION: 2,
            PRESSURE: 0,
            PRESSURE_ITERATIONS: 20,
            CURL: 0.1,
            SPLAT_RADIUS: 0.05,
            SPLAT_FORCE: 500,
            SPLAT_COUNT: parseInt(Math.random() * 20) + 5,
            SHADING: true,
            COLORFUL: true,
            COLOR_UPDATE_SPEED: 100,
            PAUSED: false,
            BACK_COLOR: { r: 245, g: 246, b: 255 },
            TRANSPARENT: true,
            BLOOM: false,
            BLOOM_ITERATIONS: 8,
            BLOOM_RESOLUTION: 256,
            BLOOM_INTENSITY: 0.8,
            BLOOM_THRESHOLD: 0.6,
            BLOOM_SOFT_KNEE: 0.7,
            SUNRAYS: true,
            SUNRAYS_RESOLUTION: 196,
            SUNRAYS_WEIGHT: 0.3,
          });
        };
    
        // Wait for the component to be fully loaded before initializing the Fluid simulations
        if (cursorCanvas.current) {
          initFluidSimulations();
        }
    
      }, [cursorCanvas]);
    
  return (
    <div className='workSection'id='work'>
      <div className='blur'></div>
      <div className='titleContainer'>
        <p className='title'>work</p>
      </div>
      <div className='workContainer'>
        <a className='workPiece acne fadein' href='/acne' title='acne'>
          <div className='textContainer'>
            <p>ACNE Creative</p>
          </div>
          <div className='overlay'></div>
            <video id='ACNEPhoneVideo' className='phoneVideo' muted>
              <source src={ACNEPhoneVid} type="video/mp4" />
            </video>
            <img alt='acnePhones' src={ACNEPhoneImage} className='phoneImage'/>
        </a>
        <a className='workPiece garbs fadein' href='/garbs' title='garbs'>
          <div className='textContainer'>
            <p>Garbs</p>
          </div>
          <div className='overlay'></div>
            <video id='ACNEPhoneVideo' className='phoneVideo' muted>
              <source src={GarbsPhoneVid} type="video/mp4" />
            </video>
            <img alt='garbsPhones' src={GarbsPhoneImage} className='phoneImage'/>
        </a>
        <a className='workPiece deloitte fadein' href='/deloitte' title='deloitte'>
          <div className='textContainer'>
            <p>Deloitte</p>
          </div>
          <div className='overlay'></div>
            <video id='ACNEPhoneVideo' className='phoneVideo' muted>
              <source src={DeloittePhoneVid} type="video/mp4" />
            </video>
            <img alt='deloittePhones' src={DeloittePhoneImage} className='phoneImage'/>   
        </a>
        <a className='workPiece nhs fadein' href='/nhs' title='nhs'>
          <div className='textContainer'>
            <p>NHS</p>
          </div>
          <div className='overlay'></div>
            <video id='ACNEPhoneVideo' className='phoneVideo' muted>
              <source src={NHSPhoneVid} type="video/mp4" />
            </video>
            <img alt='nhsPhones' src={NHSPhoneImage} className='phoneImage'/>   
        </a>
        <a className='workPiece selectors fadein' href='/selectors' title='selectors'>
          <div className='textContainer'>
            <p>Selectors</p>
          </div>
          <div className='overlay'></div>
            <video id='ACNEPhoneVideo' className='phoneVideo' muted>
              <source src={SelectorsPhoneVid} type="video/mp4" />
            </video>
            <img alt='selectorsPhones' src={SelectorsPhoneImage} className='phoneImage'/>      
        </a>
        <div className='workPiece comingSoon fadein' title='coming soon'>
          <div className='textContainer'>
            <p>Coming soon</p>
          </div>
          <div className='overlay'></div>
            <img alt='comingSoonPhones' src={ComingSoonPhoneImage} className='phoneImage'/>    
        </div>
      </div>
      <canvas ref={cursorCanvas} className="webgl"></canvas>
    </div>
  );
}

export default Work;
