import React, { useState, useEffect } from 'react';
import DeloittePhone from '../../assets/pages/workPages/deloitte/deloittePhone.png';
import DeloitteLandingMobile from '../../assets/pages/workPages/deloitte/deloitteLandingMobile.png';
import {motion as m} from 'framer-motion';

function Deloitte() {
    const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const translateYUp = -5 + ((scrollProgress * 2) * -0.6);
            const translateYDown = -40 + (scrollProgress * 0.6);
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100); // Convert 6rem to pixels and calculate scale

            document.querySelectorAll('.up').forEach(element => {
                element.style.transform = `translateY(${translateYUp}vh)`;
            });

            document.querySelectorAll('.down').forEach(element => {
                element.style.transform = `translateY(${translateYDown}vh)`;
            });

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
      useEffect(() => {
        const handleScroll = () => {
            const contentElement = document.querySelector('.content');
            const workPageHeader = document.querySelector('.workPageHeader');
          
            if (contentElement && workPageHeader) {
              const topOfContent = contentElement.getBoundingClientRect().top;
              const headerHeight = workPageHeader.offsetHeight;
              const threshold = -headerHeight / 2;
          
              if (topOfContent <= -threshold) {
                workPageHeader.classList.add('switch');
                setHeaderSwitch(true);
              } else {
                workPageHeader.classList.remove('switch');
                setHeaderSwitch(false);
              }
            }
          };
          
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='deloittePage'>
        <m.div
            className='deloitteOverlay'
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: .5, delay: .5 }}
        ></m.div>
        <div className={`workPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9) translate(-12.5vw, -12.5vh)'}} animate={{transform: 'scale(1) translate(-12.5vw, -12.5vh)'}} transition={{duration: .4, delay: .75, ease: "easeOut"}} exit={{opacity: 0}} className='grid'>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='deloittePhone' src={DeloittePhone}/>
                    </div>
                </m.div>
            </m.div>
            <div className='landingMobile'>
                <img alt="deloitte landing image" src={DeloitteLandingMobile}/>
            </div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>Deloitte</p>
                    <p className='slogan'>Full-time consultant alongside completing a technological degree</p>
                </div>
                <div>
                    <p className='date'>2019-2023</p>
                    <p className='role'>Consultant</p>
                </div>
            </div>
            <div className='body'>
                <div className='textContainer'>
                    <p>Deloitte is an international professional services network and a leading global provider of audit, consulting and financial advisory. In September 2019, after completing my A-Levels at Ada (a specialist computer science college), I joined Deloitte through their BrightStart scheme as an apprentice. It was a four year apprenticeship, working full-time within the company, alongside completing a 3 year BSC in Digital and Technological Solutions. I worked on a wide range of projects and engagements throughout my time at the company, building up not only my technical skillset but also becoming well-versed in professional services and client facing interactions. Below you’ll find an overview into the work I completed and the skills I developed.</p>
                    <p className='contentHeading'>Development</p>
                    <p>From a developmental standpoint, I accomplished a wide range of goals throughout my time at Deloitte. Prior to joining, I studied Computer Science at sixth-form, as well as completing a range of personal web development projects. This kickstarted my passion for development and helped me grasp a foundational understanding of the base web protocols. Through my first development role I learnt the ways of working within a professional team, as well as gaining a comprehensive understanding of the wider scope of work within a technical project. As my communication/team skills developed, I became a lot more proficient in working closely with design teams and fellow developers, to bring wireframes to life. Over the four years, I progressed from my initial position as a junior developer, to leading the development side of projects. I continued to develop my tech stack and area of expertise, learning a variety of JavaScript frameworks and API’s, as well as many backend protocols and content management integrations. My feedback from clients and fellow team members was always exemplary, I feel proud of the work I achieved here.</p>
                    <p className='contentHeading'>Consultancy</p>
                    <p>I worked within consulting at Deloitte, meaning the majority of my work was client facing. Working this way supported the development of my professional skillset, particularly around proactive communication, business knowledge and adaptability, to ensure client expectations were exceeded upon. I worked with a vast range of clientele, both from public and private sectors, resulting in my ability to excel in a variety of different environments. I led and organised training both in person and virtually, bringing clients up to speed on new processes and enhancing their skillsets. As well as working closely with in-house client teams to deliver professional solutions. Working with a range of different people in different environments, has enabled me to develop my own personal communication skills and confidence, supporting me to actively listen and share my own ideas.</p>
                    <div className='navContainer'>
                        <a className='garbs' href='/garbs' title='previous'>
                            <div>
                                <p>garbs</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>garbs</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>garbs</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                        <a className='nhs' href='/nhs' title='next'>
                            <div>
                                <p>nhs</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>nhs</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>nhs</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Deloitte;
