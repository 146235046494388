import React, { useState, useEffect } from 'react';

function Footer() {
  return (
    <div className='footer'>
      <div className='container'>
        <div>
          <p className='title'>Alfie Batten</p>
          <p className='slogan'>Software Developer And Designer</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
