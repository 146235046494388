import React, { useRef, useState, useEffect } from 'react';
import Fluid from 'webgl-fluid';
import JournalOneImage from '../assets/components/journalComponent/journalOneImage.png';
import JournalOneImageHover from '../assets/components/journalComponent/journalOneImageHover.png';

function Journal() {
  const cursorCanvas = useRef(null);

  useEffect(() => {
    const initFluidSimulations = () => {

      // Fluid simulation for the cursor canvas
      Fluid(cursorCanvas.current, {
        IMMEDIATE: false,
        TRIGGER: 'hover',
        SIM_RESOLUTION: 32,
        DYE_RESOLUTION: 1024,
        CAPTURE_RESOLUTION: 512,
        DENSITY_DISSIPATION: 5,
        VELOCITY_DISSIPATION: 2,
        PRESSURE: 0,
        PRESSURE_ITERATIONS: 20,
        CURL: 0.1,
        SPLAT_RADIUS: 0.1,
        SPLAT_FORCE: 500,
        SPLAT_COUNT: parseInt(Math.random() * 20) + 5,
        SHADING: true,
        COLORFUL: true,
        COLOR_UPDATE_SPEED: 100,
        PAUSED: false,
        BACK_COLOR: { r: 245, g: 246, b: 255 },
        TRANSPARENT: true,
        BLOOM: false,
        BLOOM_ITERATIONS: 8,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: 0.8,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: 0.3,
      });
    };

    // Wait for the component to be fully loaded before initializing the Fluid simulations
    if (cursorCanvas.current) {
      initFluidSimulations();
    }

  }, [cursorCanvas]);

  return (
    <div className='journalSection' id='journal'>
      <div className='titleContainer'>
        <p className='title'>Journal</p>
      </div>
      <div className='journalContainer'>
        <a className='journalPiece fadein blenderPiece' href='/3d-asset-creation-with-blender' title='3d asset creation with blender'>
          <div className='textContainer'>
            <p>3D Asset Creation with Blender</p>
          </div>
          <div className='overlay'></div>   
          <img alt='journalOneImage' className='backgroundImage' src={JournalOneImage}/>   
          <img alt='journalOneImageHover'className='backgroundImageHover' src={JournalOneImageHover}/>    
 
        </a>
        <div className='journalPiece fadein comingSoon' title='coming soon'>
          <div className='textContainer'>
            <p>Coming soon</p>
          </div>
          <div className='overlay'></div>       
        </div>
      </div>
      <canvas ref={cursorCanvas} className="webgl"></canvas>
    </div>
  );
}

export default Journal;
