import React, { useState, useEffect } from 'react';
import Journal1Landing from '../../assets/pages/journalPages/journalOne/journalOneImage.png';
import ScooterOne from '../../assets/pages/journalPages/journalOne/scooterOne.png';
import ScooterTwo from '../../assets/pages/journalPages/journalOne/scooterTwo.png';
import ScooterThree from '../../assets/pages/journalPages/journalOne/scooterThree.png';
import ScooterFour from '../../assets/pages/journalPages/journalOne/scooterFour.png';
import ScooterFive from '../../assets/pages/journalPages/journalOne/scooterFive.png';
import ScooterSix from '../../assets/pages/journalPages/journalOne/scooterSix.png';


import {motion as m} from 'framer-motion';
function Journal1() {
  const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100); // Convert 6rem to pixels and calculate scale

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
      const handleScroll = () => {
          const contentElement = document.querySelector('.content');
          const journalPageHeader = document.querySelector('.journalPageHeader');
        
          if (contentElement && journalPageHeader) {
            const topOfContent = contentElement.getBoundingClientRect().top;
            const headerHeight = journalPageHeader.offsetHeight;
            const threshold = -headerHeight / 2;
        
            if (topOfContent <= -threshold) {
              journalPageHeader.classList.add('switch');
              setHeaderSwitch(true);
            } else {
              journalPageHeader.classList.remove('switch');
              setHeaderSwitch(false);
            }
          }
        };
        
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <div className='journalPage'>
          <div className={`journalPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9)'}} animate={{transform: 'scale(1)'}} transition={{duration: .4, delay: .25, ease: "easeOut"}} exit={{opacity: 0}}>
                <img alt='journal one landing image' src={Journal1Landing}/>
            </m.div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>3D Asset Creation with Blender</p>
                </div>
                <div></div>
            </div>
            <div className='body'>
                <div className='textContainer'>
                    <p>During lockdown I set out to teach myself the art of 3D design. Having already gained vast experience within the design and development field, as well as teaching myself music production, to broaden my skillset further I wanted to delve into the world of 3D asset creation. My initial interest was inspired from discovering VFX artist and online creator Clinton Jones (also known as pwnisher). He’s known for running online competitions in which thousands of 3D designers create renders using a provided reference model. I quickly fell in love with the idea of being able to create these virtual, magical environments seen in the submissions, and therefore my journey of learning 3D design began.</p>
                    <p>On researching, I discovered Blender was widely used and cost free, making it the ideal platform for developing a new skill. Over the course of the next few months I read up on the fundamentals and workflow of Blender, as well as watching many tutorials on the aspects of 3D design. It felt like a minefield at first, but I had a goal in mind to create my own models from scratch, and the plethora of tools on hand that Blender offers me enabled me to teach myself to do that.</p>
                    <p>One project that I set myself was to create a collection of one of a kind scooters under the brand name Scoot. My challenge was to model, texture and render a collection of scooters completely from scratch. First I needed to create a detailed base model as a reference, that I could then use to develop interesting and individual pieces. I successfully created six pieces for this collection, I felt satisfaction with the end result but more so the skills I learnt along the way. The project ended there, as it was purely conducted as a learning exercise for myself, the renders can be seen below.</p>
                    <p>Having been 3D modelling for a few years now, I can confidently say that my knowledge and interest in the field continues to grow. I have a sound base understanding for the fundamental processes, and have created and sold pieces of 3D art I feel immensely proud of. I still have much to learn, but I’m passionate about finding ways of integrating my 3D design skills into my development projects. Such as the use of Spline tool and Three.js.</p>
                </div>
                <div className='imageContainer'>
                    <img alt='scooterOne' src={ScooterOne}/>
                    <img alt='scooterTwo' src={ScooterTwo}/>
                    <img alt='scooterThree' src={ScooterThree}/>
                    <img alt='scooterFour' src={ScooterFour}/>
                    <img alt='scooterFive' src={ScooterFive}/>
                    <img alt='scooterSix' src={ScooterSix}/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Journal1;
