import React, { useState, useEffect } from 'react';
import GarbsPhone from '../../assets/pages/workPages/garbs/garbsPhone.png'
import GarbsLandingMobile from '../../assets/pages/workPages/garbs/garbsLandingMobile.png'
import GarbsImage from '../../assets/pages/workPages/garbs/garbsImage.png'
import GarbsImageMobile from '../../assets/pages/workPages/garbs/garbsImageMobile.png'
import {motion as m} from 'framer-motion';

function Garbs() {
    const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const translateYUp = -5 + ((scrollProgress * 2) * -0.6);
            const translateYDown = -40 + (scrollProgress * 0.6);
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100); // Convert 6rem to pixels and calculate scale

            document.querySelectorAll('.up').forEach(element => {
                element.style.transform = `translateY(${translateYUp}vh)`;
            });

            document.querySelectorAll('.down').forEach(element => {
                element.style.transform = `translateY(${translateYDown}vh)`;
            });

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
      useEffect(() => {
        const handleScroll = () => {
            const contentElement = document.querySelector('.content');
            const workPageHeader = document.querySelector('.workPageHeader');
          
            if (contentElement && workPageHeader) {
              const topOfContent = contentElement.getBoundingClientRect().top;
              const headerHeight = workPageHeader.offsetHeight;
              const threshold = -headerHeight / 2;
          
              if (topOfContent <= -threshold) {
                workPageHeader.classList.add('switch');
                setHeaderSwitch(true);
              } else {
                workPageHeader.classList.remove('switch');
                setHeaderSwitch(false);
              }
            }
          };
          
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='garbsPage'>
        <m.div
            className='garbsOverlay'
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: .5, delay: .5 }}
        ></m.div>
        <div className={`workPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9) translate(-12.5vw, -12.5vh)'}} animate={{transform: 'scale(1) translate(-12.5vw, -12.5vh)'}} transition={{duration: .4, delay: .75, ease: "easeOut"}} exit={{opacity: 0}} className='grid'>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='garbsPhone' src={GarbsPhone}/>
                    </div>
                </m.div>
            </m.div>
            <div className='landingMobile'>
                <img alt='garbs landing image' src={GarbsLandingMobile}/>
            </div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>Garbs</p>
                    <p className='slogan'>Website and brand redesign for an early-career photographer and videographer</p>
                </div>
                <div>
                    <p className='date'>2024</p>
                    <p className='role'>Software Developer</p>
                </div>
            </div>
            <div className='body'>
                <div className='textContainer'>
                    <p>Garbs is a photographer, videographer, director and early-career creative from London. They reached out to me, seeking to establish a better brand identity to expand their client base. To do so, they wanted their existing site redesigned and developed from the ground up to better showcase their work and evidence their expertise in the field.</p>
                    <p className='contentHeading'>Ideation</p>
                    <p>They were unsure how they wanted their new site to look, other than enabling the visual content to speak for itself. I reviewed their current site and identified how the structure could be redesigned to make the content more accessible and visually appealing. There wasn’t a consistent brand logo, and the typography styles didn’t match the feel of the brand. Therefore a rebrand was needed with structured guidelines in place. I collated a range of websites from other photographers and videographers, to gain a better feel for how others in the field market their content, prior to designing the new brand and site wireframes.</p>
                    <p className='contentHeading'>Design</p>
                    <p>With the above in mind, I defined the different sections of the site. A landing page, consisting of a hero image and a navigation section. A photography page, housing all the named photography collections. A Film page, housing all the video content. A header, including their logo and Instagram and a footer. I blocked these out in Figma, creating low-level designs to use as a base for later, before grouping all the photography content into collections to better showcase the individual pieces of work. I then set out to create a more professional and recognisable logo, as well as defining consistent typography styles for headings and text. Once this was completed, I built on the base designs from earlier. Adding in the logo, headings, and visual content; as well as defining an overall colour palette. I then finalised the designs, making alignment tweaks and setting out how mouse and scroll interactions would work, leaving me with high-level designs to reference in development. The client at this stage was extremely pleased, stating the designs had exceeded their expectations.</p>
                    <p className='contentHeading'>Development</p>
                    <p>The old site was built using Cargo; an online website builder, meaning the new site would need to be built from the ground up. The new site was developed in React, making use of JSON and webpack to build server-side and locally; storing the codebase in a Bitbucket repository. I chose Netlify as my deployment service provider because it provides affordable and lightweight solutions for small-scale businesses. The sections defined earlier became individual React components with corresponding SCSS files; as well as the standalone photography and film pages, making use of React routes for switching. Once the site visually matched the Figma wireframes, I began setting out the various website animations, such as: fixing the landing hero and having it blur and zoom on scroll while the rest of the content scrolls in over the top, fixing photography and film titles to the top right of the screen and having them dynamically change based on the collection being viewed, as well as overall hover events and creating a custom mouse cursor. The last stage was to ensure website efficiency, to do so I ran a series of lighthouse reports to gather metrics on performance, SEO and best practices, before implementing all the suggested.</p>
                    <p className='contentHeading'>Result</p>
                    <p>They were extremely pleased with the new site, stating that’s it’s given them a fantastic platform to professionally showcase their work. They look forward to the months ahead to see how their client base will expand, and feel they are in a much better position to go ahead and thrive within the media industry.</p>
                    <div>
                        <img alt='garbs image desktop' src={GarbsImage} className="desktop"/>
                        <img alt='garbs image mobile' src={GarbsImageMobile} className="mobile"/>
                    </div>
                    <div className='navContainer'>
                        <a className='acne' href='/acne' title='previous'>
                            <div>
                                <p>acne</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>acne</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>acne</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                        <a className='deloitte' href='/deloitte' title='next'>
                            <div>
                                <p>deloitte</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>deloitte</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>deloitte</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Garbs;
