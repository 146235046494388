import React, { useState, useEffect } from 'react';
import SelectorsPhone from '../../assets/pages/workPages/selectors/selectorsPhone.png';
import SelectorsLandingMobile from '../../assets/pages/workPages/selectors/selectorsLandingMobile.png';
import SelectorsImage from '../../assets/pages/workPages/selectors/selectorsImage.png'
import SelectorsImageMobile from '../../assets/pages/workPages/selectors/selectorsImageMobile.png'
import {motion as m} from 'framer-motion';

function Selectors() {
    const [headerSwitch, setHeaderSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollProgress = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            const translateYUp = -5 + ((scrollProgress * 2) * -0.6);
            const translateYDown = -40 + (scrollProgress * 0.6);
            const scale = 1 - ((6 * 16) / window.innerWidth) * (scrollProgress / 100); // Convert 6rem to pixels and calculate scale

            document.querySelectorAll('.up').forEach(element => {
                element.style.transform = `translateY(${translateYUp}vh)`;
            });

            document.querySelectorAll('.down').forEach(element => {
                element.style.transform = `translateY(${translateYDown}vh)`;
            });

            document.querySelector('.landing').style.transform = `scale(${scale})`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
      useEffect(() => {
        const handleScroll = () => {
            const contentElement = document.querySelector('.content');
            const workPageHeader = document.querySelector('.workPageHeader');
          
            if (contentElement && workPageHeader) {
              const topOfContent = contentElement.getBoundingClientRect().top;
              const headerHeight = workPageHeader.offsetHeight;
              const threshold = -headerHeight / 2;
          
              if (topOfContent <= -threshold) {
                workPageHeader.classList.add('switch');
                setHeaderSwitch(true);
              } else {
                workPageHeader.classList.remove('switch');
                setHeaderSwitch(false);
              }
            }
          };
          
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='selectorsPage'>
        <m.div
            className='selectorsOverlay'
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: .5, delay: .5 }}
        ></m.div>
        <div className={`workPageHeader ${headerSwitch ? 'switch' : ''}`}>
            <a href='../' title='home'>
                <div>
                    <p>home</p>
                    <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
                <div>
                    <p>home</p>
                    <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                    </svg>
                </div>
            </a>
        </div>
        <div className='landing'>
            <m.div initial={{transform: 'scale(.9) translate(-12.5vw, -12.5vh)'}} animate={{transform: 'scale(1) translate(-12.5vw, -12.5vh)'}} transition={{duration: .4, delay: .75, ease: "easeOut"}} exit={{opacity: 0}} className='grid'>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                </m.div>
                <m.div className='down row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-40vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                </m.div>
                <m.div className='up row' initial={{transform: 'translateY(-28vh)'}} animate={{transform: 'translateY(-5vh)'}} transition={{duration: .6, delay: .9, ease: "easeOut"}}>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                    <div className='imageContainer'>
                        <img alt='selectorsPhone' src={SelectorsPhone}/>
                    </div>
                </m.div>
            </m.div>
            <div className='landingMobile'>
                <img alt='selectors landing image' src={SelectorsLandingMobile}/>
            </div>
        </div>
        <div className='content'>
            <div className='info'>
                <div>
                    <p className='heading'>Selectors</p>
                    <p className='slogan'>Designing and Developing a site to kickstart digital presence</p>
                </div>
                <div>
                    <p className='date'>2023</p>
                    <p className='role'>Developer</p>
                </div>
            </div>
            <div className='body'>
                <div className='textContainer'>
                    <p>Selectors is a music and lifestyle company created to encapsulate the love for discovering, sharing and collecting art and music. I joined the team as a freelance designer, developer and project consultant to build out their site and kickstart their digital presence.</p>
                    <p className='contentHeading'>Research</p>
                    <p>Prior to working out the design and visual layout of the site, it was imperative that I gained a good concept of any ideas they had in mind to fully understand their vision. I held many in depth meetings with the team, to share ideas and aims and develop an understanding for the ethos and core passions of the company. In order to portray their values to end users. Following these initial meetings, mood boards were created and competitors were researched, resulting in some base guidelines and leaving me in a good position to structure out the site.</p>
                    <p className='contentHeading'>Design</p>
                    <p>I chose to design the site wireframes using Figma as I have extensive experience with the tool. Using the guidelines and current brand material, I created a colour palette and defined typography styles before fleshing out low-level wireframe designs. I was developing the site in collaboration with the rest of the team, who were themselves building out the other aspects of the company. Which was a new startup and therefore in the early days of development. This meant that I began developing the site before we had fully completed wireframes, as visual assets were still being created but allowed us to test ideas in production before having fully committed to them in the design stage. The designs were reworked many times as the company played around with different ideas before settling on a design the whole team agreed on.</p>
                    <p className='contentHeading'>Development</p>
                    <p>I developed the site in React with Bitbucket as a code repository. I used Netlify to deal with deploying stage and production branches using Node.js and webpack. I utilised the component structure of React to ensure code efficiency and legibility and opted to style the site using the program language Sass. The team wanted the public to be able to join their early supporters list, in order for them to be continually updated. To do this I developed a backend database on Sanity, which I then connected to the frontend site, resulting in the users submissions being sent and stored in the backend dashboard. Once the site visually matched the designs and shared vision, I began building out the interactivity of elements as well as ensuring the responsiveness of all components on various devices and browsers. Lastly, through completing a series of lighthouse reports, I ensured the site was optimised in its performance, SEO and overall best practices.</p>
                    <p className='contentHeading'>Result</p>
                    <p>The whole team at Selectors are incredibly pleased with the end result of the website. Stating it’s left them in a fantastic place to go and build their digital brand and gain a substantial customer base. Working on this project as a sole developer gave me autonomy over the development decisions. This has been a new way of working and brought me valuable experience and opportunities for growth and professional development. In ways that I’d not previously had when working in bigger teams and not in a freelance capacity.</p>
                    <div>
                        <img alt='selectors image desktop' src={SelectorsImage} className="desktop"/>
                        <img alt='selectors image mobile' src={SelectorsImageMobile} className="mobile"/>
                    </div>
                    <div className='navContainer'>
                        <a className='nhs' href='/nhs' title='previous'>
                            <div>
                                <p>nhs</p>
                                <svg className='one' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>nhs</p>
                                <svg className='two' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                            <div>
                                <p>nhs</p>
                                <svg className='three' width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.9428 8.73342L0.274111 8.73342V13.2878L20.9428 13.2878L14.8793 18.8008L18.4213 22.0212L30.5312 11.0106L18.4213 0L14.8793 3.22041L20.9428 8.73342Z" fill="black"/>
                                </svg>
                            </div>
                        </a>
                        <a title='next'></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Selectors;
